const LOCAL_STORAGE_KEY = 'hiddenAnnouncements';
const container = document.getElementById('js-announcements');

function getPreviouslyHiddenAnnouncements() {
  return localStorage.getItem(LOCAL_STORAGE_KEY) || '';
}

function saveHiddenAnnouncement(id) {
  const previouslyHidden = getPreviouslyHiddenAnnouncements();
  if (previouslyHidden.split(',').indexOf(id) > -1) {
    return;
  }

  localStorage.setItem(LOCAL_STORAGE_KEY,
      [previouslyHidden, id].filter(Boolean).join(','));
}

function hideAnnouncementFromDom(id) {
  const announcement = document.getElementById('notice_' + id);
  if (!announcement || !announcement.parentNode) {
    /* If this happens, there is a bug. */
    console.error('Valid announcement not found! ID:', id);
    return;
  }
  announcement.parentNode.removeChild(announcement);
}

function hideAnnouncement(id) {
  saveHiddenAnnouncement(id);
  hideAnnouncementFromDom(id);
}

function addCloseHandlers() {
  [].forEach.call(
    container.querySelectorAll('.js-announcement-close'),
    function(button) {
      button.addEventListener('click', function() {
        hideAnnouncement(button.dataset.announcementId);
      });
    }
  );
}

(function insertAnnouncements() {
  if (!container) {
    return;
  }

  const xhr = new XMLHttpRequest();
  const previouslyHidden = getPreviouslyHiddenAnnouncements();
  const hiddenParameter = previouslyHidden ?
      '&hiddenAnnouncements=' + previouslyHidden : '';
  xhr.open('GET', '/.announcements?v=2' + hiddenParameter);
  xhr.onload = function() {
    try {
      const data = JSON.parse(xhr.responseText);
      const markup = data && data.announcementPartial;
      if (!markup || !container) {
        return;
      }
      container.innerHTML = markup;
      addCloseHandlers();
    } catch(e) {
      console.error(e);
    }
  };
  xhr.send();
})();
