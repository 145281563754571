/* For the purposes of implementing a zoom effect, this toggles the given class
 * on and off the given element whenever some element, from now on referred to
 * as the "trigger", is clicked. The trigger will typically be a button,
 *
 * Also adds appropriate ARIA attributes to the trigger, and toggles an
 * 'is-zoomed' class on/off on the trigger.
 *
 * To use, give the trigger the following attributes:
 * - 'data-zoomable', set to the ID of the element whose class should be
 *   toggled
 * - 'data-zoomable-class', set to the class that should be toggled on/off
 *   (e.g. "gallery__item--zoomed")
 *
 * The initial zoom state is off (classes absent), unless the 'is-zoomed' class
 * is present in the HTML source.
 */
(function() {
  var triggerZoomedClass = 'is-zoomed';
  var triggers = document.querySelectorAll('[data-zoomable]');
  [].forEach.call(triggers, function(trigger) {
    var zoomableId = trigger.getAttribute('data-zoomable');
    var zoomableClass = trigger.getAttribute('data-zoomable-class');
    if (!zoomableId || !zoomableClass) {
      return;
    }
    trigger.addEventListener('click', function() {
      var target = document.getElementById(zoomableId);
      if (!target || (typeof target.classList) !== 'object') {
        return;
      }
      var isZoomed = trigger.classList.contains(triggerZoomedClass);
      if (isZoomed) { /* IE11 doesn't support toggle */
        target.classList.remove(zoomableClass);
        trigger.classList.remove(triggerZoomedClass);
      } else {
        target.classList.add(zoomableClass);
        trigger.classList.add(triggerZoomedClass);
      }

      var image = target.querySelector('img[data-alt-url]');
      if (image && image.src) {
        var swapSrc = image.getAttribute('data-alt-url');
        var oldSrc = image.src;
        if (!swapSrc || swapSrc === oldSrc) {
          return;
        }

        image.src = swapSrc;
        image.setAttribute('data-alt-url', oldSrc);
      }
    });
    trigger.setAttribute('aria-controls', zoomableId);
  });
})();
