/* Enables an element to be truncated vertically, with a button to show the
 * truncated contents.
 * The button must exist in the document, but can have the 'hidden' attribute
 * set (hidden state will be disabled by this script). Its
 * 'data-truncatable-target' attribute must be set to the ID of the element
 * which is to be truncated, and the 'data-truncatable-to' attribute of that
 * target element must be set to the number of CSS pixels which must be
 * exceeded in order to trigger the "truncated" state. The target element will
 * be given the 'is-truncated' class when the "truncated" state is triggered
 * (and the class is removed otherwise).
 */
(function() {
  var TRUNCATED_CLASS = 'is-truncated';
  var triggers = document.querySelectorAll('.js-truncatable');

  function updateTruncateStatuses() {
    var eligibleSelector = '[data-truncatable-to]:not([data-truncatable-off])';
    var eligibleTargets = document.querySelectorAll(eligibleSelector);
    [].forEach.call(eligibleTargets, function(target) {
      var maxHeight = parseInt(target.getAttribute('data-truncatable-to'), 10);
      if (target.scrollHeight > maxHeight) {
        target.classList.add(TRUNCATED_CLASS);
      } else {
        target.classList.remove(TRUNCATED_CLASS);
      }
    });
  }

  [].forEach.call(triggers, function(trigger) {
    var targetId = trigger.getAttribute('data-truncatable-target');
    var target = document.getElementById(targetId);
    trigger.addEventListener('click', function() {
      target.setAttribute('data-truncatable-off', 'true');
      target.classList.remove(TRUNCATED_CLASS);
      trigger.setAttribute('aria-expanded', 'true');
    });
    trigger.setAttribute('aria-controls', targetId);
    trigger.setAttribute('aria-expanded', 'false');
    trigger.hidden = false;
  });

  updateTruncateStatuses();
  window.addEventListener('resize', updateTruncateStatuses);
})();
