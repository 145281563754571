/* Sets up a simple confirmation dialog for each button that wants one.
 *
 * To use, give the button element a 'data-confirm' attribute that's set to the
 * string that should be used as a prompt in the dialog.
 *
 * The dialog will be triggered in the bubbling phase of the 'click' event, and
 * if the dialog was confirmed, the event will be passed through to allow
 * normal click/activation behavior. Otherwise, such behavior (e.g. form
 * submission in the case of a submit button) is prevented.
 */
(function() {
  var elements = document.querySelectorAll('[data-confirm]');
  [].forEach.call(elements, function(element) {
    var confirmString = element.dataset.confirm;
    element.addEventListener('click', function(event) {
      var actionConfirmed = window.confirm(confirmString);
      if (!actionConfirmed) {
        event.preventDefault();
        event.stopPropagation();
      }
    });
  });
})();
