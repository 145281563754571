/* Sets up a toggle-state form to "dynamically" update by using XHR
 *
 */
(function() {
  var BUTTON_CLASS_BASE = 'toggle-state__button';
  /* TODO: Final, generic state names/classes, in backend */
  var BUTTON_CLASS_UNSET = BUTTON_CLASS_BASE + '--unset';
  var BUTTON_CLASS_PENDING = BUTTON_CLASS_BASE + '--pending';
  var BUTTON_CLASS_YES = BUTTON_CLASS_BASE + '--approved';
  var BUTTON_CLASS_NO = BUTTON_CLASS_BASE + '--denied';

  function setup(form) {
    if (!FormData) {
      return;
    }
    var button = form.querySelector('.' + BUTTON_CLASS_BASE);

    form.addEventListener('submit', function(event) {
      event.preventDefault();
      var formData = new FormData(form);
      var xhr = new XMLHttpRequest();
      xhr.addEventListener('load', function() {
        try {
          var responseObject = JSON.parse(this.responseText).data.attributes;
          update(button, responseObject);
        } catch(e) {
          alert(e);
        }
      });
      xhr.open(form.method, form.action);
      xhr.send(formData);
    });
  }

  function update(button, newState) {
    button.classList.remove(BUTTON_CLASS_UNSET);
    button.classList.remove(BUTTON_CLASS_PENDING);
    button.classList.remove(BUTTON_CLASS_YES);
    button.classList.remove(BUTTON_CLASS_NO);
    button.classList.add(BUTTON_CLASS_BASE + '--' + newState.state);
    button.textContent = newState.label;
    button.title = newState.title;
    button.setAttribute('aria-label', newState.title);
  }

  var forms = document.querySelectorAll('.js-toggle-state');
  [].forEach.call(forms, setup);
})();
